<template>
  <div
    class="
      hover:bg-gray-50
      dark:hover:bg-gray-770
      w-full
      flex
      items-center
      rounded-lg
      py-3
      px-2
    "
  >
    <router-link
      :to="`/service/service_applications/${item.service_application_id}`"
      class="block w-full"
    >
      <div class="py-2">
        <div class="sm:flex flex-wrap">
          <ItemText title="#" :text="item.service_application_id" />
          <ItemText title="Datums" :text="item.date" />
          <template v-if="item.user">
            <ItemText title="Izveidoja" :text="item.user.name" />
          </template>
          <ItemText title="Statuss">
            <template v-slot:content>
              <Badge
                :small="true"
                :text="item.status.display_name"
                :className="`${statusColor(item.status)} mr-2 mb-2 md:mb-0`"
              />
            </template>
          </ItemText>
          <ItemText title="Skaitītāju rādījumi" :text="item.meter_readings" />
          <template v-if="item.notes">
            <ItemText title="Piezīmes" :text="item.notes" />
          </template>
          <template v-if="item.user_notes">
            <ItemText title="Meistara piezīmes" :text="item.user_notes" />
          </template>
        </div>
      </div>
    </router-link>

    <div class="button">
      <template v-if="dataLoading">
        <Loading />
      </template>
      <template v-else>
        <Button
          :icon="showPositions ? 'chevron-up' : 'chevron-down'"
          @click="getInventoryItemServicePositions"
        />
      </template>
    </div>
  </div>

  <template v-if="inventoryItemServicePositions && showPositions">
    <template v-for="item in inventoryItemServicePositions" :key="item.id">
      <div
        class="
          w-full
          grid grid-cols-12
          items-center
          rounded-lg
          py-1
          pl-6
          pr-2
          block
          w-full
        "
      >
        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
          <ItemText title="Nosaukums" :text="item.name" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <ItemText title="Cena" :text="item.total_with_discount" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <ItemText title="Daudzums" :text="item.quantity" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-2">
          <ItemText title="Tips">
            <template v-slot:content>
              <Badge
                :small="true"
                :text="item.type.name"
                :className="`${
                  item.type.id == 1
                    ? 'bg-pink-600 text-pink-100 mr-2 mb-2 md:mb-0'
                    : 'bg-yellow-100 text-yellow-800 mr-2 mb-2 md:mb-0'
                }`"
              />
            </template>
          </ItemText>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import Colors from "@/services/Helpers/colors";
import ItemText from "@/components/Components/ItemText";
import Badge from "@/components/Components/Badge";
import Loading from "@/components/Components/Loading";

export default {
  name: "SingleServiceableItemHistoryEntry",
  components: {
    ItemText,
    Badge,
    Loading,
  },
  data() {
    return {
      showPositions: false,
      dataLoading: false,
      inventoryItemServicePositions: null,
    };
  },
  props: ["item", "inventoryItem"],
  methods: {
    statusColor(status) {
      let key = status ? status.id : 0;
      return Colors.serviceStatusColor(key);
    },
    getInventoryItemServicePositions() {
      this.showPositions = !this.showPositions;

      if (!this.inventoryItemServicePositions) {
        this.dataLoading = true;
        this.$store
          .dispatch("getInventoryItemServicePositions", {
            inventoryItem: this.inventoryItem.id,
            serviceApplication: this.item.service_application_id,
          })
          .then((response) => {
            this.inventoryItemServicePositions = response.data;
            this.dataLoading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>