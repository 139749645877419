<template>
  <item-card>
    <template v-slot:title>
      <h3
        class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300"
      >
        Servisa vēsture
      </h3>
    </template>
    <template v-slot:buttons>
      <template v-if="serviceApplications.length > 3">
        <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
      </template>
    </template>
    <template v-slot:content>
      <template v-if="orderHistory.length > 0">
        <div class="flex flex-col w-full divide-y divide-gray-200">
          <template
            v-for="item in orderHistory"
            :key="item.service_application_id"
          >
            <div>
              <SingleServiceableItemHistoryEntry
                :item="item"
                :inventoryItem="inventoryItem"
              />
            </div>
          </template>
        </div>
      </template>
    </template>
  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard";
import SingleServiceableItemHistoryEntry from "@/components/OrderHistory/SingleServiceableItemHistoryEntry";

export default {
  name: "InventoryItemServiceHistory",
  components: {
    ItemCard,
    SingleServiceableItemHistoryEntry,
  },
  props: ["serviceApplications", "inventoryItem"],
  data() {
    return {
      showFullList: false,
    };
  },
  computed: {
    orderHistory() {
      return this.showFullList
        ? this.serviceApplications
        : this.serviceApplications.slice(0, 3);
    },
  },
};
</script>

<style>
</style>